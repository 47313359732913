.b-country-switch {
	@include font(medium, 16px, 22px);

	margin-bottom: rh(6);

	&_flag {
		vertical-align: middle;
	}

	&_button {
		@include font(regular, 14px, 18px);

		background: none;
		border: 0;
		padding: 0;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
