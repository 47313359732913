/* stylelint-disable */
.tingle-modal {
	-webkit-tap-highlight-color: transparent;
	align-items: center;
	background: color(modal_overlay);
	bottom: 0;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	left: 0;
	opacity: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	transition: transform 0.2s ease;
	visibility: hidden;
	z-index: 1000;

	&.tingle-modal--visible {
		visibility: visible;
		opacity: 1;
	}

	&.tingle-modal--noClose &__close {
		display: none;
	}

	&-box {
		padding: 0;
		max-height: 95vh;
		overflow-y: auto;
	}

	&-box__content{
		text-align: center;
		max-height: initial;
		overflow-y: hidden;
		padding: rh(15);

		@include media(m) {
			padding: rh(12 10);
		}

		@include media(s) {
			padding: rh(1);
		}
	}

	&__close {
		appearance: none;
		background-color: transparent;
		border: none;
		cursor: pointer;
		padding: 0;
		position: absolute;
		right: 20px;
		top: 20px;
		transition: color 0.3s ease;
		z-index: 1000;

		@include icon(close) {
			color: color(dark);
			font-size: 17px;
			font-weight: bold;
		};

		&:hover::before {
			color: color(lighter1);
		}

		@include media(s) {
			right: 10px;
			top: 10px;
		}
	}

	&__closeLabel,
	&__closeIcon {
		display: none;
	}

	&-box {
		background: color(light);
		cursor: auto;
		flex-shrink: 0;
		margin-bottom: auto;
		margin-top: auto;
		max-width: 900px;
		opacity: 1;
		position: relative;
		transform: scale(.8);
		transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);

		@include media(s) {
			margin-top: 20px;
			width: 90%;
		}
	}

	&.m-bonus_product &-box {
		@include media(s) {
			margin-top: 20px;
		}
	}

	&.m-narrow &-box {
		@include media(m) {
			width: auto;
		}
	}

	&.tingle-modal--visible &-box {
		transform: scale(1);
	}

	&-box__content {
		display: flex;
		justify-content: center;
		max-height: 95vh;
		overflow-y: auto;
		padding: 60px;

		@include media(s) {
			padding: 40px 20px;
		}
	}

	&.m-bonus_product &-box__content {
		padding: 0;
		overflow: hidden;

		@include media(s) {
			padding: 0;
			max-height: 90vh;
		}
	}

	&.m-checkout_login &-box {
		max-width: 400px;
	}

	&.m-checkout_parcel &-box {
		width: 100%;
		height: 100%;
		max-width: 80vw;
		max-height: 80vh;
	}

	&.m-checkout_parcel &-box__content {
		padding: 0;
		overflow: hidden;

		@include media(s) {
			padding: 0;
			max-height: 90vh;
		}
	}
}

.b-parcel_modal {
	width: 100%;
	height: 100%;
}

.b-parcel_modal iframe {
	width: 100%;
	height: 79vh;
	min-height: 100%;
}

.b-parcel_details {
	margin-top: 20px;
}

.b-click_collect_parcel {
	display: flex;
    justify-content: center;
    padding: 10px !important;
    flex-direction: row;
    flex-wrap: wrap;
}

.parcel-shop-error-msg {
	margin-bottom: 10px;
}

.b-click_collect_parcel_logo {
	width: 100%;
    height: 60px;
	background-image: url("../../images/evri.svg");
	background-repeat: no-repeat;
	background-position: center;
}


// States
body.tingle-enabled {
	position: fixed;
	overflow: hidden;
	left: 0;
	right: 0;
}
/* stylelint-enable */
