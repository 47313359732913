.l-homepage {
	--section-margin-bottom: rh(25);

	@include media(l) {
		--section-margin-bottom: rh(20);
	}

	@include media(m) {
		--section-margin-bottom: rh(15);
	}

	@include media(s) {
		--section-margin-bottom: rh(12);
	}

	.b-hero_slider {
		margin-bottom: rh(5);

		@include media(s) {
			margin-bottom: 0;
		}
	}

	.b-hero_noslider,
	.b-products_slider,
	.b-section {
		margin-bottom: var(--section-margin-bottom);
	}

	.b-section {
		&.m-homepage_big_gap .b-section-column {
			padding: rh(0 10);

			@include media(l) {
				padding: rh(0 6);
			}

			@include media(m) {
				padding: rh(0 4);
			}

			@include media(s) {
				padding: 0;
			}
		}
	}
}
