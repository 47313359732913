/* stylelint-disable */
.tns-outer {
	position: relative;
	z-index: 1;
}

.tns-outer [hidden] {
	display: none !important;
}

.tns-horizontal.tns-subpixel {
	white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
	display: inline-block;
	vertical-align: top;
	white-space: normal
}

.tns-horizontal.tns-no-subpixel:after{
	content:'';
	display:table;
	clear:both
}
.tns-horizontal.tns-no-subpixel>.tns-item{
	float:left
}
.tns-horizontal.tns-carousel.tns-no-subpixel>.tns-item{
	margin-right:-100%
}
.tns-no-calc{
	position:relative;
	left:0
}
.tns-gallery{
	position:relative;
	left:0;
	min-height:1px
}
.tns-gallery>.tns-item{
	position:absolute;
	left:-100%;
	-webkit-transition:transform 0s, opacity 0s;
	-moz-transition:transform 0s, opacity 0s;
	transition:transform 0s, opacity 0s
}
.tns-gallery>.tns-slide-active{
	position:relative;
	left:auto !important
}
.tns-gallery>.tns-moving{
	-webkit-transition:all 0.25s;
	-moz-transition:all 0.25s;
	transition:all 0.25s
}
.tns-autowidth{
	display:inline-block
}
.tns-lazy-img{
	-webkit-transition:opacity 0.6s;
	-moz-transition:opacity 0.6s;
	transition:opacity 0.6s;
	opacity:0.6
}
.tns-lazy-img.loaded{
	opacity:1
}
.tns-ah{
	-webkit-transition:height 0s;
	-moz-transition:height 0s;
	transition:height 0s
}
.tns-ovh{
	overflow:hidden
}
.tns-visually-hidden{
	position:absolute;
	left:-10000em
}
.tns-transparent{
	opacity:0;
	visibility:hidden
}
.tns-fadeIn{
	opacity:1;
	filter:alpha(opacity=100);
	z-index:0
}
.tns-normal,.tns-fadeOut{
	opacity:0;
	filter:alpha(opacity=0);
	z-index:-1
}
.tns-t-subp2{
	margin:0 auto;
	width:310px;
	position:relative;
	height:10px;
	overflow:hidden
}
.tns-t-ct{
	width:2333.3333333%;
	width:-webkit-calc(100% * 70 / 3);
	width:-moz-calc(100% * 70 / 3);
	width:calc(100% * 70 / 3);
	position:absolute;
	right:0
}
.tns-t-ct:after{
	content:'';
	display:table;
	clear:both
}
.tns-t-ct>div{
	width:1.4285714%;
	width:-webkit-calc(100% / 70);
	width:-moz-calc(100% / 70);
	width:calc(100% / 70);
	height:10px;
	float:left
}
/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */

// TODO: Need refactoring
.customize_wrapper{
	margin: 0 auto;
	position: relative;
	width: 100%;

	@include media(m-up) {
		max-width: 400px;
	}

	@include media(s) {
		max-width: 240px;
	}
}

.visually-hidden {
	position: fixed;
	left: -10000px;
	opacity: 0;
}

.thumbnails {
	display: flex;
	justify-content: center;
	margin-top: 45px;
}

.thumbnails li {
	border: 1px solid color(transparent);
	cursor: pointer;
	margin: 0 10px;
	width: 70px;
	line-height: 68px;

	@include media(m) {
		flex-shrink: 1;
		width: 60px;
	}
}

.thumbnails .tns-nav-active {
	border-color: color(lighter5);
}

.thumbnails img {
	height: auto;
	max-height: 70px;
	vertical-align: middle;
}

.customize-thumbnails li {
	color: color(lighter2);
	cursor: pointer;
	font-size: 0;
	margin-top: -15px;
	outline: none;
	position: absolute;
	top: 190px;

	&:hover {
		color: color(dark);
	}
}

.tns-controls [aria-controls] {
	background: none;
	border: 0;
	font-size: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.tns-controls button[data-controls="prev"] {
	left: -44px;

	@include icon(arrow-left-thin) {
		font-size: 30px;
	};

	@include media(m) {
		left: -36px;

		&::before {
			font-size: 24px;
		}
	}
}

.tns-controls button[data-controls="next"] {
	right: -44px;

	@include icon(arrow-right-thin) {
		font-size: 30px;
	}

	@include media(m) {
		right: -36px;

		&::before {
			font-size: 24px;
		}
	}
}

.tns-controls button:hover {
	opacity: 0.4;
}

.tns-controls button:focus {
	outline: 0;
}

.tns-controls {
	@include media(s m) {
		outline: none;
	}
}

.lt-ie9 .tns-controls > [aria-controls] {
	line-height: 2.5em;
}

.tns-controls [disabled] {
	color: #999999;
	background: #B3B3B3;
	cursor: not-allowed !important;
}
.tns-nav {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media(m-down) {
		display: none;
	}
}
.tns-nav > [aria-controls] {
	width: 6px;
	height: 6px;
	padding: 0;
	margin: 0 5px;
	border-radius: 50%;
	background: #ddd;
	border: 0;
}

.tns-nav .tns-nav-active {
	background: color(dark);
}

.tns-nav button:focus {
	opacity: 0.7;
	outline: 0;
}

.playbutton-wrapper { text-align: center; }
.playbutton-wrapper button { height: 34px; padding: 0 10px; font-size: 14px; background: #fff; border-radius: 3px; border: 1px solid  #ccc; cursor: pointer; }
.wrapper { padding: 0 5em; }
.suite-container .title,
.suite-container .subtitle,
.suite-container li { padding: 5px 10px; }
.suite-container li {
	font-size: 12px;
	line-height: 1.4;
	background: #f5f5f5;
}
.suite-container .title { font-size: 14px; font-weight: bold; background: #8acac6; text-transform: capitalize; }
.suite-container .subtitle { font-size: 13px; font-weight: bold; background: #dedede; text-transform: capitalize; }
.suite-container .fail { background: #FFADE2; }

[class*="vertical"] .img { padding: 30px 0; }
[class*="vertical"] .img:before { content: ''; display: inline-block; width: 1px; margin-right: -1px; height: 100%; vertical-align: middle; }
[class*="vertical"] .img a { display: inline-block; padding: 0; }
[class*="vertical"] .img-1 { height: 137px; }
[class*="vertical"] .img-2 { height: 122px; }
[class*="vertical"] .img-3 { height: 143px; }
[class*="vertical"] .img-4 { height: 325px; }
[class*="vertical"] .img-5 { height: 140px; }
[class*="vertical"] .img-6 { height: 119px; }
[class*="vertical"] .img-7 { height: 223px; }
[class*="vertical"] .img-8 { height: 164px; }
/*[class*="vertical"] p { display: none; }*/

/*.animation .img-1 { height: 137px; }
.animation .img-2 { height: 122px; }
.animation .img-3 { height: 143px; }
.animation .img-4 { height: 325px; }
.animation .img-5 { height: 140px; }
.animation .img-6 { height: 119px; }
.animation .img-7 { height: 223px; }
.animation .img-8 { height: 164px; }*/

/* reset prism */
pre[class*="language-"] { margin: 0; }

/* test results */
.test-results {
	line-height: 1.6;
	line-height: 1.6rem;
	padding: 1.6em 10%;
	background-color: #f2f2f2;
}

/* go to */
.goto-controls {
	margin-bottom: 10px;
	text-align: center;
}
.goto-controls input,
.goto-controls .button {
	display: inline-block;
	font-size: 14px;
	height: 2.6em;
	line-height: 2.6;
	padding: 0 1em;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	vertical-align: middle;
}
.goto-controls input {
	border: 1px solid  #ccc;
}
.goto-controls .button {
	min-width: 4em;
	border: none;
	color: #fff;
	background-color: #333;
	cursor: pointer;
}

/* test results */
.test-results .title { font-weight: bold; text-transform: capitalize; }
.test-results .title:nth-child(n+2) { margin-top: 1em; }
.test-results [class*="item"] { margin-left: 1em; font-size: 0.9em; }
.test-results [class*="item"] { font-weight: 300; }
.test-results [class*="item"]:after { margin-left: 0.5em; }
.test-results .item-success { color: #0EB80E; }
.test-results .item-success:after { content: 'âœ“'; }
.test-results .item-fail { color: #ED1E1E; }
.test-results .item-fail:after { content: 'âœ—'; }
.test-results .item-comment { font-style: italic; color: #666; }
.test-results .item-notsure:after { content: 'ï¼Ÿ'; }
.test-results .item-running:after {
	display: inline-block;
	height: 16px;
	content: url('../images/pinwheel.svg');
	vertical-align: middle;
}
.customize-tools [hidden] { display: none; }


/* https://daneden.github.io/animate.css/ */
@-webkit-keyframes jello {
	from,
	11.1%,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	22.2% {
		-webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}

	33.3% {
		-webkit-transform: skewX(6.25deg) skewY(6.25deg);
		transform: skewX(6.25deg) skewY(6.25deg);
	}

	44.4% {
		-webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	55.5% {
		-webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}

	66.6% {
		-webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}

	77.7% {
		-webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}

	88.8% {
		-webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}

@keyframes jello {
	from,
	11.1%,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	22.2% {
		-webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}

	33.3% {
		-webkit-transform: skewX(6.25deg) skewY(6.25deg);
		transform: skewX(6.25deg) skewY(6.25deg);
	}

	44.4% {
		-webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	55.5% {
		-webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}

	66.6% {
		-webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}

	77.7% {
		-webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}

	88.8% {
		-webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}

.jello {
	-webkit-animation-name: jello;
	animation-name: jello;
	-webkit-transform-origin: center;
	transform-origin: center;
}

@-webkit-keyframes rollOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}

@keyframes rollOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}

.rollOut {
	-webkit-animation-name: rollOut;
	animation-name: rollOut;
}
.zoom {
	position: relative;
	display: inline-block;
	overflow: hidden;
}

.zoom > a {
		cursor: zoom-in;
}

.zoom.is-error > a {
		cursor: not-allowed;
}

.zoom.is-loading > a {
		cursor: progress;
}

.zoom > a > img {
		display: block;
}

.zoom-notice {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 150;
		width: 10em;
		margin: -1em 0 0 -5em;
		line-height: 2em;
		text-align: center;
		background: #FFF;
		box-shadow: 0 0 10px #888;
}

.zoom-flyout {
		position:absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 200;
		overflow: hidden;
		background: #FFF;
		cursor: crosshair;
}

.zoom-flyout img {
		width: auto;
		max-width: none;
}

/* variations */
.zoom--overflow {
		overflow: visible;
}

.zoom--overflow .zoom-flyout {
		left: 105%;
}
/* stylelint-enable */
