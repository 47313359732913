/* stylelint-disable */
.pswp__button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
	transition: opacity 0.2s;
}

.pswp__button:focus,
.pswp__button:hover {
	opacity: 1;
}

.pswp__button:active {
	opacity: 0.9;
	outline: none;
}

.pswp__button ::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.pswp__button--close {
	color: color(lighter1);
	padding: 20px;
	position: absolute;
	right: 0;
	top: 0;

	@include icon(close) {
		font-size: 17px;
	}

	&:hover {
		color: color(dark);
	}
}
/* pswp__ui--over-close class it added when mouse is over element that should close gallery */
.pswp__ui--over-close .pswp__button--close {
	opacity: 1;
}

.pswp__button--fs,
.pswp__button--zoom {
	display: none;
}

.pswp--supports-fs .pswp__button--fs {
	display: block;
}

/* Arrow buttons hit area (icon is added to :before pseudo-element) */
.pswp__button--arrow--left,
.pswp__button--arrow--right {
	background: color(pdp_slider_arrow);
	border-radius: 50%;
	border: 1px solid color(lighter4);
	cursor: pointer;
	height: 38px;
	margin-top: -19px;
	opacity: 1;
	position: absolute;
	text-align: center;
	top: 50%;
	transition: background 0.2s linear;
	width: 38px;

	@include media(m-down) {
		height: 30px;
		margin-top: -15px;
		width: 30px;
	}

	&:hover {
		background: color(pdp_slider_arrow_hover);
		opacity: 1;

		&::before {
			color: color(lighter2);
		}
	}

	&::before {
		color: color(dark);
		font-size: 10px;
		line-height: 36px;
		transition: color 0.2s linear;

		@include media(m-down) {
			line-height: 28px;
		}
	}
}

.pswp__button--arrow--left {
	left: 20px;

	@include icon(arrow-left);
}

.pswp__button--arrow--right {
	right: 20px;

	@include icon(arrow-right);
}

/* 6. Additional styles */
/* root element of UI */
.pswp__ui {
	-webkit-font-smoothing: auto;
	opacity: 1;
	visibility: visible;
	z-index: 1550;
}

.pswp__caption,
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
	backface-visibility: hidden;
	transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
	will-change: opacity;
}
/* pswp--has_mouse class is added only when two subsequent mousemove events occur */
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
	transition: background 0.2s linear;
	visibility: visible;
}

/* pswp__ui--hidden class is added when controls are hidden e.g. when user taps to toggle visibility of controls */
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right {
	opacity: 0.001; /* Force paint & create composition layer for controls. */
}
/* pswp__ui--one-slide class is added when there is just one item in gallery */
.pswp__ui--one-slide .pswp__button--arrow--left,
.pswp__ui--one-slide .pswp__button--arrow--right,
.pswp__ui--one-slide .pswp__counter {
	display: none;
}

.pswp__element--disabled {
	display: none !important;
}
/* stylelint-enable */
