/* stylelint-disable selector-class-pattern */
.flagstrap {
    display: inline-block;
    position: relative;
    width: 100%;
}

.flagstrap-icon {
    display: inline-block;
    width: 20px;
    height: 11px;
    background-image: url("../../images/flags.png");
    background-repeat: no-repeat;
	background-color: #DBDBDB;
	background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
	.flagstrap-icon {
		background-image: url("../../images/flags@2x.png");
	}
}

.flagstrap-placeholder,
.flagstrap-placeholder i {
  display: none
}

.flagstrap-icon.flagstrap-be {
	width: 18px;
}

.flagstrap-icon.flagstrap-ch {
	width: 15px;
}

.flagstrap-icon.flagstrap-mc {
	width: 19px;
}

.flagstrap-icon.flagstrap-ne {
	width: 18px;
}

.flagstrap-icon.flagstrap-np {
	width: 13px;
}

.flagstrap-icon.flagstrap-va {
	width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
	.flagstrap-icon {
		background-size: 5630px 15px;
	}
}

.flagstrap-icon.flagstrap-ac {
	height: 10px;
	background-position: 0px 0px;
}

.flagstrap-icon.flagstrap-ad {
	height: 14px;
	background-position: -22px 0px;
}

.flagstrap-icon.flagstrap-ae {
	height: 10px;
	background-position: -44px 0px;
}

.flagstrap-icon.flagstrap-af {
	height: 14px;
	background-position: -66px 0px;
}

.flagstrap-icon.flagstrap-ag {
	height: 14px;
	background-position: -88px 0px;
}

.flagstrap-icon.flagstrap-ai {
	height: 10px;
	background-position: -110px 0px;
}

.flagstrap-icon.flagstrap-al {
	height: 15px;
	background-position: -132px 0px;
}

.flagstrap-icon.flagstrap-am {
	height: 10px;
	background-position: -154px 0px;
}

.flagstrap-icon.flagstrap-ao {
	height: 14px;
	background-position: -176px 0px;
}

.flagstrap-icon.flagstrap-aq {
	height: 14px;
	background-position: -198px 0px;
}

.flagstrap-icon.flagstrap-ar {
	height: 13px;
	background-position: -220px 0px;
}

.flagstrap-icon.flagstrap-as {
	height: 10px;
	background-position: -242px 0px;
}

.flagstrap-icon.flagstrap-at {
	height: 14px;
	background-position: -264px 0px;
}

.flagstrap-icon.flagstrap-au {
	height: 10px;
	background-position: -286px 0px;
}

.flagstrap-icon.flagstrap-aw {
	height: 14px;
	background-position: -308px 0px;
}

.flagstrap-icon.flagstrap-ax {
	height: 13px;
	background-position: -330px 0px;
}

.flagstrap-icon.flagstrap-az {
	height: 10px;
	background-position: -352px 0px;
}

.flagstrap-icon.flagstrap-ba {
	height: 10px;
	background-position: -374px 0px;
}

.flagstrap-icon.flagstrap-bb {
	height: 14px;
	background-position: -396px 0px;
}

.flagstrap-icon.flagstrap-bd {
	height: 12px;
	background-position: -418px 0px;
}

.flagstrap-icon.flagstrap-be {
	height: 15px;
	background-position: -440px 0px;
}

.flagstrap-icon.flagstrap-bf {
	height: 14px;
	background-position: -460px 0px;
}

.flagstrap-icon.flagstrap-bg {
	height: 12px;
	background-position: -482px 0px;
}

.flagstrap-icon.flagstrap-bh {
	height: 12px;
	background-position: -504px 0px;
}

.flagstrap-icon.flagstrap-bi {
	height: 12px;
	background-position: -526px 0px;
}

.flagstrap-icon.flagstrap-bj {
	height: 14px;
	background-position: -548px 0px;
}

.flagstrap-icon.flagstrap-bl {
	height: 14px;
	background-position: -570px 0px;
}

.flagstrap-icon.flagstrap-bm {
	height: 10px;
	background-position: -592px 0px;
}

.flagstrap-icon.flagstrap-bn {
	height: 10px;
	background-position: -614px 0px;
}

.flagstrap-icon.flagstrap-bo {
	height: 14px;
	background-position: -636px 0px;
}

.flagstrap-icon.flagstrap-bq {
	height: 14px;
	background-position: -658px 0px;
}

.flagstrap-icon.flagstrap-br {
	height: 14px;
	background-position: -680px 0px;
}

.flagstrap-icon.flagstrap-bs {
	height: 10px;
	background-position: -702px 0px;
}

.flagstrap-icon.flagstrap-bt {
	height: 14px;
	background-position: -724px 0px;
}

.flagstrap-icon.flagstrap-bv {
	height: 15px;
	background-position: -746px 0px;
}

.flagstrap-icon.flagstrap-bw {
	height: 14px;
	background-position: -768px 0px;
}

.flagstrap-icon.flagstrap-by {
	height: 10px;
	background-position: -790px 0px;
}

.flagstrap-icon.flagstrap-bz {
	height: 14px;
	background-position: -812px 0px;
}

.flagstrap-icon.flagstrap-ca {
	height: 10px;
	background-position: -834px 0px;
}

.flagstrap-icon.flagstrap-cc {
	height: 10px;
	background-position: -856px 0px;
}

.flagstrap-icon.flagstrap-cd {
	height: 15px;
	background-position: -878px 0px;
}

.flagstrap-icon.flagstrap-cf {
	height: 14px;
	background-position: -900px 0px;
}

.flagstrap-icon.flagstrap-cg {
	height: 14px;
	background-position: -922px 0px;
}

.flagstrap-icon.flagstrap-ch {
	height: 15px;
	background-position: -944px 0px;
}

.flagstrap-icon.flagstrap-ci {
	height: 14px;
	background-position: -961px 0px;
}

.flagstrap-icon.flagstrap-ck {
	height: 10px;
	background-position: -983px 0px;
}

.flagstrap-icon.flagstrap-cl {
	height: 14px;
	background-position: -1005px 0px;
}

.flagstrap-icon.flagstrap-cm {
	height: 14px;
	background-position: -1027px 0px;
}

.flagstrap-icon.flagstrap-cn {
	height: 14px;
	background-position: -1049px 0px;
}

.flagstrap-icon.flagstrap-co {
	height: 14px;
	background-position: -1071px 0px;
}

.flagstrap-icon.flagstrap-cp {
	height: 14px;
	background-position: -1093px 0px;
}

.flagstrap-icon.flagstrap-cr {
	height: 12px;
	background-position: -1115px 0px;
}

.flagstrap-icon.flagstrap-cu {
	height: 10px;
	background-position: -1137px 0px;
}

.flagstrap-icon.flagstrap-cv {
	height: 12px;
	background-position: -1159px 0px;
}

.flagstrap-icon.flagstrap-cw {
	height: 14px;
	background-position: -1181px 0px;
}

.flagstrap-icon.flagstrap-cx {
	height: 10px;
	background-position: -1203px 0px;
}

.flagstrap-icon.flagstrap-cy {
	height: 13px;
	background-position: -1225px 0px;
}

.flagstrap-icon.flagstrap-cz {
	height: 14px;
	background-position: -1247px 0px;
}

.flagstrap-icon.flagstrap-de {
	height: 12px;
	background-position: -1269px 0px;
}

.flagstrap-icon.flagstrap-dg {
	height: 10px;
	background-position: -1291px 0px;
}

.flagstrap-icon.flagstrap-dj {
	height: 14px;
	background-position: -1313px 0px;
}

.flagstrap-icon.flagstrap-dk {
	height: 15px;
	background-position: -1335px 0px;
}

.flagstrap-icon.flagstrap-dm {
	height: 10px;
	background-position: -1357px 0px;
}

.flagstrap-icon.flagstrap-do {
	height: 13px;
	background-position: -1379px 0px;
}

.flagstrap-icon.flagstrap-dz {
	height: 14px;
	background-position: -1401px 0px;
}

.flagstrap-icon.flagstrap-ea {
	height: 14px;
	background-position: -1423px 0px;
}

.flagstrap-icon.flagstrap-ec {
	height: 14px;
	background-position: -1445px 0px;
}

.flagstrap-icon.flagstrap-ee {
	height: 13px;
	background-position: -1467px 0px;
}

.flagstrap-icon.flagstrap-eg {
	height: 14px;
	background-position: -1489px 0px;
}

.flagstrap-icon.flagstrap-eh {
	height: 10px;
	background-position: -1511px 0px;
}

.flagstrap-icon.flagstrap-er {
	height: 10px;
	background-position: -1533px 0px;
}

.flagstrap-icon.flagstrap-es {
	height: 14px;
	background-position: -1555px 0px;
}

.flagstrap-icon.flagstrap-et {
	height: 10px;
	background-position: -1577px 0px;
}

.flagstrap-icon.flagstrap-eu {
	height: 14px;
	background-position: -1599px 0px;
}

.flagstrap-icon.flagstrap-fi {
	height: 12px;
	background-position: -1621px 0px;
}

.flagstrap-icon.flagstrap-fj {
	height: 10px;
	background-position: -1643px 0px;
}

.flagstrap-icon.flagstrap-fk {
	height: 10px;
	background-position: -1665px 0px;
}

.flagstrap-icon.flagstrap-fm {
	height: 11px;
	background-position: -1687px 0px;
}

.flagstrap-icon.flagstrap-fo {
	height: 15px;
	background-position: -1709px 0px;
}

.flagstrap-icon.flagstrap-fr {
	height: 14px;
	background-position: -1731px 0px;
}

.flagstrap-icon.flagstrap-ga {
	height: 15px;
	background-position: -1753px 0px;
}

.flagstrap-icon.flagstrap-gb {
	height: 10px;
	background-position: -1775px 0px;
}

.flagstrap-icon.flagstrap-gd {
	height: 12px;
	background-position: -1797px 0px;
}

.flagstrap-icon.flagstrap-ge {
	height: 14px;
	background-position: -1819px 0px;
}

.flagstrap-icon.flagstrap-gf {
	height: 14px;
	background-position: -1841px 0px;
}

.flagstrap-icon.flagstrap-gg {
	height: 14px;
	background-position: -1863px 0px;
}

.flagstrap-icon.flagstrap-gh {
	height: 14px;
	background-position: -1885px 0px;
}

.flagstrap-icon.flagstrap-gi {
	height: 10px;
	background-position: -1907px 0px;
}

.flagstrap-icon.flagstrap-gl {
	height: 14px;
	background-position: -1929px 0px;
}

.flagstrap-icon.flagstrap-gm {
	height: 14px;
	background-position: -1951px 0px;
}

.flagstrap-icon.flagstrap-gn {
	height: 14px;
	background-position: -1973px 0px;
}

.flagstrap-icon.flagstrap-gp {
	height: 14px;
	background-position: -1995px 0px;
}

.flagstrap-icon.flagstrap-gq {
	height: 14px;
	background-position: -2017px 0px;
}

.flagstrap-icon.flagstrap-gr {
	height: 14px;
	background-position: -2039px 0px;
}

.flagstrap-icon.flagstrap-gs {
	height: 10px;
	background-position: -2061px 0px;
}

.flagstrap-icon.flagstrap-gt {
	height: 13px;
	background-position: -2083px 0px;
}

.flagstrap-icon.flagstrap-gu {
	height: 11px;
	background-position: -2105px 0px;
}

.flagstrap-icon.flagstrap-gw {
	height: 10px;
	background-position: -2127px 0px;
}

.flagstrap-icon.flagstrap-gy {
	height: 12px;
	background-position: -2149px 0px;
}

.flagstrap-icon.flagstrap-hk {
	height: 14px;
	background-position: -2171px 0px;
}

.flagstrap-icon.flagstrap-hm {
	height: 10px;
	background-position: -2193px 0px;
}

.flagstrap-icon.flagstrap-hn {
	height: 10px;
	background-position: -2215px 0px;
}

.flagstrap-icon.flagstrap-hr {
	height: 10px;
	background-position: -2237px 0px;
}

.flagstrap-icon.flagstrap-ht {
	height: 12px;
	background-position: -2259px 0px;
}

.flagstrap-icon.flagstrap-hu {
	height: 10px;
	background-position: -2281px 0px;
}

.flagstrap-icon.flagstrap-ic {
	height: 14px;
	background-position: -2303px 0px;
}

.flagstrap-icon.flagstrap-id {
	height: 14px;
	background-position: -2325px 0px;
}

.flagstrap-icon.flagstrap-ie {
	height: 10px;
	background-position: -2347px 0px;
}

.flagstrap-icon.flagstrap-il {
	height: 15px;
	background-position: -2369px 0px;
}

.flagstrap-icon.flagstrap-im {
	height: 10px;
	background-position: -2391px 0px;
}

.flagstrap-icon.flagstrap-in {
	height: 14px;
	background-position: -2413px 0px;
}

.flagstrap-icon.flagstrap-io {
	height: 10px;
	background-position: -2435px 0px;
}

.flagstrap-icon.flagstrap-iq {
	height: 14px;
	background-position: -2457px 0px;
}

.flagstrap-icon.flagstrap-ir {
	height: 12px;
	background-position: -2479px 0px;
}

.flagstrap-icon.flagstrap-is {
	height: 15px;
	background-position: -2501px 0px;
}

.flagstrap-icon.flagstrap-it {
	height: 14px;
	background-position: -2523px 0px;
}

.flagstrap-icon.flagstrap-je {
	height: 12px;
	background-position: -2545px 0px;
}

.flagstrap-icon.flagstrap-jm {
	height: 10px;
	background-position: -2567px 0px;
}

.flagstrap-icon.flagstrap-jo {
	height: 10px;
	background-position: -2589px 0px;
}

.flagstrap-icon.flagstrap-jp {
	height: 14px;
	background-position: -2611px 0px;
}

.flagstrap-icon.flagstrap-ke {
	height: 14px;
	background-position: -2633px 0px;
}

.flagstrap-icon.flagstrap-kg {
	height: 12px;
	background-position: -2655px 0px;
}

.flagstrap-icon.flagstrap-kh {
	height: 13px;
	background-position: -2677px 0px;
}

.flagstrap-icon.flagstrap-ki {
	height: 10px;
	background-position: -2699px 0px;
}

.flagstrap-icon.flagstrap-km {
	height: 12px;
	background-position: -2721px 0px;
}

.flagstrap-icon.flagstrap-kn {
	height: 14px;
	background-position: -2743px 0px;
}

.flagstrap-icon.flagstrap-kp {
	height: 10px;
	background-position: -2765px 0px;
}

.flagstrap-icon.flagstrap-kr {
	height: 14px;
	background-position: -2787px 0px;
}

.flagstrap-icon.flagstrap-kw {
	height: 10px;
	background-position: -2809px 0px;
}

.flagstrap-icon.flagstrap-ky {
	height: 10px;
	background-position: -2831px 0px;
}

.flagstrap-icon.flagstrap-kz {
	height: 10px;
	background-position: -2853px 0px;
}

.flagstrap-icon.flagstrap-la {
	height: 14px;
	background-position: -2875px 0px;
}

.flagstrap-icon.flagstrap-lb {
	height: 14px;
	background-position: -2897px 0px;
}

.flagstrap-icon.flagstrap-lc {
	height: 10px;
	background-position: -2919px 0px;
}

.flagstrap-icon.flagstrap-li {
	height: 12px;
	background-position: -2941px 0px;
}

.flagstrap-icon.flagstrap-lk {
	height: 10px;
	background-position: -2963px 0px;
}

.flagstrap-icon.flagstrap-lr {
	height: 11px;
	background-position: -2985px 0px;
}

.flagstrap-icon.flagstrap-ls {
	height: 14px;
	background-position: -3007px 0px;
}

.flagstrap-icon.flagstrap-lt {
	height: 12px;
	background-position: -3029px 0px;
}

.flagstrap-icon.flagstrap-lu {
	height: 12px;
	background-position: -3051px 0px;
}

.flagstrap-icon.flagstrap-lv {
	height: 10px;
	background-position: -3073px 0px;
}

.flagstrap-icon.flagstrap-ly {
	height: 10px;
	background-position: -3095px 0px;
}

.flagstrap-icon.flagstrap-ma {
	height: 14px;
	background-position: -3117px 0px;
}

.flagstrap-icon.flagstrap-mc {
	height: 15px;
	background-position: -3139px 0px;
}

.flagstrap-icon.flagstrap-md {
	height: 10px;
	background-position: -3160px 0px;
}

.flagstrap-icon.flagstrap-me {
	height: 10px;
	background-position: -3182px 0px;
}

.flagstrap-icon.flagstrap-mf {
	height: 14px;
	background-position: -3204px 0px;
}

.flagstrap-icon.flagstrap-mg {
	height: 14px;
	background-position: -3226px 0px;
}

.flagstrap-icon.flagstrap-mh {
	height: 11px;
	background-position: -3248px 0px;
}

.flagstrap-icon.flagstrap-mk {
	height: 10px;
	background-position: -3270px 0px;
}

.flagstrap-icon.flagstrap-ml {
	height: 14px;
	background-position: -3292px 0px;
}

.flagstrap-icon.flagstrap-mm {
	height: 14px;
	background-position: -3314px 0px;
}

.flagstrap-icon.flagstrap-mn {
	height: 10px;
	background-position: -3336px 0px;
}

.flagstrap-icon.flagstrap-mo {
	height: 14px;
	background-position: -3358px 0px;
}

.flagstrap-icon.flagstrap-mp {
	height: 10px;
	background-position: -3380px 0px;
}

.flagstrap-icon.flagstrap-mq {
	height: 14px;
	background-position: -3402px 0px;
}

.flagstrap-icon.flagstrap-mr {
	height: 14px;
	background-position: -3424px 0px;
}

.flagstrap-icon.flagstrap-ms {
	height: 10px;
	background-position: -3446px 0px;
}

.flagstrap-icon.flagstrap-mt {
	height: 14px;
	background-position: -3468px 0px;
}

.flagstrap-icon.flagstrap-mu {
	height: 14px;
	background-position: -3490px 0px;
}

.flagstrap-icon.flagstrap-mv {
	height: 14px;
	background-position: -3512px 0px;
}

.flagstrap-icon.flagstrap-mw {
	height: 14px;
	background-position: -3534px 0px;
}

.flagstrap-icon.flagstrap-mx {
	height: 12px;
	background-position: -3556px 0px;
}

.flagstrap-icon.flagstrap-my {
	height: 10px;
	background-position: -3578px 0px;
}

.flagstrap-icon.flagstrap-mz {
	height: 14px;
	background-position: -3600px 0px;
}

.flagstrap-icon.flagstrap-na {
	height: 14px;
	background-position: -3622px 0px;
}

.flagstrap-icon.flagstrap-nc {
	height: 10px;
	background-position: -3644px 0px;
}

.flagstrap-icon.flagstrap-ne {
	height: 15px;
	background-position: -3666px 0px;
}

.flagstrap-icon.flagstrap-nf {
	height: 10px;
	background-position: -3686px 0px;
}

.flagstrap-icon.flagstrap-ng {
	height: 10px;
	background-position: -3708px 0px;
}

.flagstrap-icon.flagstrap-ni {
	height: 12px;
	background-position: -3730px 0px;
}

.flagstrap-icon.flagstrap-nl {
	height: 14px;
	background-position: -3752px 0px;
}

.flagstrap-icon.flagstrap-no {
	height: 15px;
	background-position: -3774px 0px;
}

.flagstrap-icon.flagstrap-np {
	height: 15px;
	background-position: -3796px 0px;
}

.flagstrap-icon.flagstrap-nr {
	height: 10px;
	background-position: -3811px 0px;
}

.flagstrap-icon.flagstrap-nu {
	height: 10px;
	background-position: -3833px 0px;
}

.flagstrap-icon.flagstrap-nz {
	height: 10px;
	background-position: -3855px 0px;
}

.flagstrap-icon.flagstrap-om {
	height: 10px;
	background-position: -3877px 0px;
}

.flagstrap-icon.flagstrap-pa {
	height: 14px;
	background-position: -3899px 0px;
}

.flagstrap-icon.flagstrap-pe {
	height: 14px;
	background-position: -3921px 0px;
}

.flagstrap-icon.flagstrap-pf {
	height: 14px;
	background-position: -3943px 0px;
}

.flagstrap-icon.flagstrap-pg {
	height: 15px;
	background-position: -3965px 0px;
}

.flagstrap-icon.flagstrap-ph {
	height: 10px;
	background-position: -3987px 0px;
}

.flagstrap-icon.flagstrap-pk {
	height: 14px;
	background-position: -4009px 0px;
}

.flagstrap-icon.flagstrap-pl {
	height: 13px;
	background-position: -4031px 0px;
}

.flagstrap-icon.flagstrap-pm {
	height: 14px;
	background-position: -4053px 0px;
}

.flagstrap-icon.flagstrap-pn {
	height: 10px;
	background-position: -4075px 0px;
}

.flagstrap-icon.flagstrap-pr {
	height: 14px;
	background-position: -4097px 0px;
}

.flagstrap-icon.flagstrap-ps {
	height: 10px;
	background-position: -4119px 0px;
}

.flagstrap-icon.flagstrap-pt {
	height: 14px;
	background-position: -4141px 0px;
}

.flagstrap-icon.flagstrap-pw {
	height: 13px;
	background-position: -4163px 0px;
}

.flagstrap-icon.flagstrap-py {
	height: 11px;
	background-position: -4185px 0px;
}

.flagstrap-icon.flagstrap-qa {
	height: 8px;
	background-position: -4207px 0px;
}

.flagstrap-icon.flagstrap-re {
	height: 14px;
	background-position: -4229px 0px;
}

.flagstrap-icon.flagstrap-ro {
	height: 14px;
	background-position: -4251px 0px;
}

.flagstrap-icon.flagstrap-rs {
	height: 14px;
	background-position: -4273px 0px;
}

.flagstrap-icon.flagstrap-ru {
	height: 14px;
	background-position: -4295px 0px;
}

.flagstrap-icon.flagstrap-rw {
	height: 14px;
	background-position: -4317px 0px;
}

.flagstrap-icon.flagstrap-sa {
	height: 14px;
	background-position: -4339px 0px;
}

.flagstrap-icon.flagstrap-sb {
	height: 10px;
	background-position: -4361px 0px;
}

.flagstrap-icon.flagstrap-sc {
	height: 10px;
	background-position: -4383px 0px;
}

.flagstrap-icon.flagstrap-sd {
	height: 10px;
	background-position: -4405px 0px;
}

.flagstrap-icon.flagstrap-se {
	height: 13px;
	background-position: -4427px 0px;
}

.flagstrap-icon.flagstrap-sg {
	height: 14px;
	background-position: -4449px 0px;
}

.flagstrap-icon.flagstrap-sh {
	height: 10px;
	background-position: -4471px 0px;
}

.flagstrap-icon.flagstrap-si {
	height: 10px;
	background-position: -4493px 0px;
}

.flagstrap-icon.flagstrap-sj {
	height: 15px;
	background-position: -4515px 0px;
}

.flagstrap-icon.flagstrap-sk {
	height: 14px;
	background-position: -4537px 0px;
}

.flagstrap-icon.flagstrap-sl {
	height: 14px;
	background-position: -4559px 0px;
}

.flagstrap-icon.flagstrap-sm {
	height: 15px;
	background-position: -4581px 0px;
}

.flagstrap-icon.flagstrap-sn {
	height: 14px;
	background-position: -4603px 0px;
}

.flagstrap-icon.flagstrap-so {
	height: 14px;
	background-position: -4625px 0px;
}

.flagstrap-icon.flagstrap-sr {
	height: 14px;
	background-position: -4647px 0px;
}

.flagstrap-icon.flagstrap-ss {
	height: 10px;
	background-position: -4669px 0px;
}

.flagstrap-icon.flagstrap-st {
	height: 10px;
	background-position: -4691px 0px;
}

.flagstrap-icon.flagstrap-sv {
	height: 12px;
	background-position: -4713px 0px;
}

.flagstrap-icon.flagstrap-sx {
	height: 14px;
	background-position: -4735px 0px;
}

.flagstrap-icon.flagstrap-sy {
	height: 14px;
	background-position: -4757px 0px;
}

.flagstrap-icon.flagstrap-sz {
	height: 14px;
	background-position: -4779px 0px;
}

.flagstrap-icon.flagstrap-ta {
	height: 10px;
	background-position: -4801px 0px;
}

.flagstrap-icon.flagstrap-tc {
	height: 10px;
	background-position: -4823px 0px;
}

.flagstrap-icon.flagstrap-td {
	height: 14px;
	background-position: -4845px 0px;
}

.flagstrap-icon.flagstrap-tf {
	height: 14px;
	background-position: -4867px 0px;
}

.flagstrap-icon.flagstrap-tg {
	height: 13px;
	background-position: -4889px 0px;
}

.flagstrap-icon.flagstrap-th {
	height: 14px;
	background-position: -4911px 0px;
}

.flagstrap-icon.flagstrap-tj {
	height: 10px;
	background-position: -4933px 0px;
}

.flagstrap-icon.flagstrap-tk {
	height: 10px;
	background-position: -4955px 0px;
}

.flagstrap-icon.flagstrap-tl {
	height: 10px;
	background-position: -4977px 0px;
}

.flagstrap-icon.flagstrap-tm {
	height: 14px;
	background-position: -4999px 0px;
}

.flagstrap-icon.flagstrap-tn {
	height: 14px;
	background-position: -5021px 0px;
}

.flagstrap-icon.flagstrap-to {
	height: 10px;
	background-position: -5043px 0px;
}

.flagstrap-icon.flagstrap-tr {
	height: 14px;
	background-position: -5065px 0px;
}

.flagstrap-icon.flagstrap-tt {
	height: 12px;
	background-position: -5087px 0px;
}

.flagstrap-icon.flagstrap-tv {
	height: 10px;
	background-position: -5109px 0px;
}

.flagstrap-icon.flagstrap-tw {
	height: 14px;
	background-position: -5131px 0px;
}

.flagstrap-icon.flagstrap-tz {
	height: 14px;
	background-position: -5153px 0px;
}

.flagstrap-icon.flagstrap-ua {
	height: 14px;
	background-position: -5175px 0px;
}

.flagstrap-icon.flagstrap-ug {
	height: 14px;
	background-position: -5197px 0px;
}

.flagstrap-icon.flagstrap-um {
	height: 11px;
	background-position: -5219px 0px;
}

.flagstrap-icon.flagstrap-us {
	height: 11px;
	background-position: -5241px 0px;
}

.flagstrap-icon.flagstrap-uy {
	height: 14px;
	background-position: -5263px 0px;
}

.flagstrap-icon.flagstrap-uz {
	height: 10px;
	background-position: -5285px 0px;
}

.flagstrap-icon.flagstrap-va {
	height: 15px;
	background-position: -5307px 0px;
}

.flagstrap-icon.flagstrap-vc {
	height: 14px;
	background-position: -5324px 0px;
}

.flagstrap-icon.flagstrap-ve {
	height: 14px;
	background-position: -5346px 0px;
}

.flagstrap-icon.flagstrap-vg {
	height: 10px;
	background-position: -5368px 0px;
}

.flagstrap-icon.flagstrap-vi {
	height: 14px;
	background-position: -5390px 0px;
}

.flagstrap-icon.flagstrap-vn {
	height: 14px;
	background-position: -5412px 0px;
}

.flagstrap-icon.flagstrap-vu {
	height: 12px;
	background-position: -5434px 0px;
}

.flagstrap-icon.flagstrap-wf {
	height: 14px;
	background-position: -5456px 0px;
}

.flagstrap-icon.flagstrap-ws {
	height: 10px;
	background-position: -5478px 0px;
}

.flagstrap-icon.flagstrap-xk {
	height: 15px;
	background-position: -5500px 0px;
}

.flagstrap-icon.flagstrap-ye {
	height: 14px;
	background-position: -5522px 0px;
}

.flagstrap-icon.flagstrap-yt {
	height: 14px;
	background-position: -5544px 0px;
}

.flagstrap-icon.flagstrap-za {
	height: 14px;
	background-position: -5566px 0px;
}

.flagstrap-icon.flagstrap-zm {
	height: 14px;
	background-position: -5588px 0px;
}

.flagstrap-icon.flagstrap-zw {
	height: 10px;
	background-position: -5610px 0px;
}
