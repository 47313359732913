/* stylelint-disable */
/*
---
name: Icons
category: Icons
---
## Icons from an icon font
_icons.tpl - is a template for _icons.scss

_icons.scss - is an automaticaly generated SCSS file. It contains:

* placeholder class %icon - it's needed for mixin icon
* function icon-char - it's needed for mixin icon
* mixin icon - you can use this mixin to insert an icon into ::before or ::after pseudo-elements. this mixin takes two parameters: icon name (file name without a char prefix), and position (before or after)
* and icon classes to use in HTML (content assets, content slots)

```html
	<style>
		div[class\*=i-] {
			border: 1px solid black;
			float: left;
			margin: 10px;
			padding: 20px;
			text-align: center;
		}

		div[class\*=i-]::before {
			display: block;
			font-size: 50px;
			margin: 10px 0 20px;
		}
	</style>

	<div class='i-alert-before'>alert</div>

	<div class='i-arrow-down-before'>arrow-down</div>

	<div class='i-arrow-left-thin-before'>arrow-left-thin</div>

	<div class='i-arrow-left-before'>arrow-left</div>

	<div class='i-arrow-right-thin-before'>arrow-right-thin</div>

	<div class='i-arrow-right-before'>arrow-right</div>

	<div class='i-arrow-up-before'>arrow-up</div>

	<div class='i-card-before'>card</div>

	<div class='i-cart-before'>cart</div>

	<div class='i-check-before'>check</div>

	<div class='i-close-before'>close</div>

	<div class='i-data-before'>data</div>

	<div class='i-delivery-truck-before'>delivery-truck</div>

	<div class='i-facebook-before'>facebook</div>

	<div class='i-hamburger-before'>hamburger</div>

	<div class='i-heart-fill-before'>heart-fill</div>

	<div class='i-heart-before'>heart</div>

	<div class='i-info-before'>info</div>

	<div class='i-instagram-before'>instagram</div>

	<div class='i-letter-2-before'>letter-2</div>

	<div class='i-letter-before'>letter</div>

	<div class='i-link-before'>link</div>

	<div class='i-linkedin-before'>linkedin</div>

	<div class='i-list-before'>list</div>

	<div class='i-lock-before'>lock</div>

	<div class='i-logout-before'>logout</div>

	<div class='i-minus-before'>minus</div>

	<div class='i-paypal-before'>paypal</div>

	<div class='i-pinterest-before'>pinterest</div>

	<div class='i-plus-before'>plus</div>

	<div class='i-search-before'>search</div>

	<div class='i-star-before'>star</div>

	<div class='i-tick-before'>tick</div>

	<div class='i-twitter-before'>twitter</div>

	<div class='i-user-data-before'>user-data</div>

	<div class='i-user-before'>user</div>

	<div class='i-youtube-before'>youtube</div>

```
*/

@mixin g-icon {
	font-family: 'icons';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	speak: none;
	text-decoration: none;
	text-transform: none;
}

@function icon-char($filename) {
	$char: '';

	@if $filename == alert {
		$char: '';
	}

	@if $filename == arrow-down {
		$char: '';
	}

	@if $filename == arrow-left-thin {
		$char: '';
	}

	@if $filename == arrow-left {
		$char: '';
	}

	@if $filename == arrow-right-thin {
		$char: '';
	}

	@if $filename == arrow-right {
		$char: '';
	}

	@if $filename == arrow-up {
		$char: '';
	}

	@if $filename == card {
		$char: '';
	}

	@if $filename == cart {
		$char: '';
	}

	@if $filename == check {
		$char: '';
	}

	@if $filename == close {
		$char: '';
	}

	@if $filename == data {
		$char: '';
	}

	@if $filename == delivery-truck {
		$char: '';
	}

	@if $filename == facebook {
		$char: '';
	}

	@if $filename == hamburger {
		$char: '';
	}

	@if $filename == heart-fill {
		$char: '';
	}

	@if $filename == heart {
		$char: '';
	}

	@if $filename == info {
		$char: '';
	}

	@if $filename == instagram {
		$char: '';
	}

	@if $filename == letter-2 {
		$char: '';
	}

	@if $filename == letter {
		$char: '';
	}

	@if $filename == link {
		$char: '';
	}

	@if $filename == linkedin {
		$char: '';
	}

	@if $filename == list {
		$char: '';
	}

	@if $filename == lock {
		$char: '';
	}

	@if $filename == logout {
		$char: '';
	}

	@if $filename == minus {
		$char: '';
	}

	@if $filename == paypal {
		$char: '';
	}

	@if $filename == pinterest {
		$char: '';
	}

	@if $filename == plus {
		$char: '';
	}

	@if $filename == search {
		$char: '';
	}

	@if $filename == star {
		$char: '';
	}

	@if $filename == tick {
		$char: '';
	}

	@if $filename == twitter {
		$char: '';
	}

	@if $filename == user-data {
		$char: '';
	}

	@if $filename == user {
		$char: '';
	}

	@if $filename == youtube {
		$char: '';
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $font-size: false) {

	@if type-of($insert) != string {
		$font-size: $insert;
		$insert: before;
	}

	&::#{$insert} {
		@include g-icon;

		content: icon-char($filename);

		@if $font-size {
			font-size: $font-size;
		}

		@content;
	}
}

/*
#Font icon variables
*/

$icon-alert: '';

$icon-arrow-down: '';

$icon-arrow-left-thin: '';

$icon-arrow-left: '';

$icon-arrow-right-thin: '';

$icon-arrow-right: '';

$icon-arrow-up: '';

$icon-card: '';

$icon-cart: '';

$icon-check: '';

$icon-close: '';

$icon-data: '';

$icon-delivery-truck: '';

$icon-facebook: '';

$icon-hamburger: '';

$icon-heart-fill: '';

$icon-heart: '';

$icon-info: '';

$icon-instagram: '';

$icon-letter-2: '';

$icon-letter: '';

$icon-link: '';

$icon-linkedin: '';

$icon-list: '';

$icon-lock: '';

$icon-logout: '';

$icon-minus: '';

$icon-paypal: '';

$icon-pinterest: '';

$icon-plus: '';

$icon-search: '';

$icon-star: '';

$icon-tick: '';

$icon-twitter: '';

$icon-user-data: '';

$icon-user: '';

$icon-youtube: '';


@mixin icons-classes {

	.i-alert-before {
		@include icon(alert);
	}

	.i-alert-after {
		@include icon(alert, after);
	}

	.i-arrow-down-before {
		@include icon(arrow-down);
	}

	.i-arrow-down-after {
		@include icon(arrow-down, after);
	}

	.i-arrow-left-thin-before {
		@include icon(arrow-left-thin);
	}

	.i-arrow-left-thin-after {
		@include icon(arrow-left-thin, after);
	}

	.i-arrow-left-before {
		@include icon(arrow-left);
	}

	.i-arrow-left-after {
		@include icon(arrow-left, after);
	}

	.i-arrow-right-thin-before {
		@include icon(arrow-right-thin);
	}

	.i-arrow-right-thin-after {
		@include icon(arrow-right-thin, after);
	}

	.i-arrow-right-before {
		@include icon(arrow-right);
	}

	.i-arrow-right-after {
		@include icon(arrow-right, after);
	}

	.i-arrow-up-before {
		@include icon(arrow-up);
	}

	.i-arrow-up-after {
		@include icon(arrow-up, after);
	}

	.i-card-before {
		@include icon(card);
	}

	.i-card-after {
		@include icon(card, after);
	}

	.i-cart-before {
		@include icon(cart);
	}

	.i-cart-after {
		@include icon(cart, after);
	}

	.i-check-before {
		@include icon(check);
	}

	.i-check-after {
		@include icon(check, after);
	}

	.i-close-before {
		@include icon(close);
	}

	.i-close-after {
		@include icon(close, after);
	}

	.i-data-before {
		@include icon(data);
	}

	.i-data-after {
		@include icon(data, after);
	}

	.i-delivery-truck-before {
		@include icon(delivery-truck);
	}

	.i-delivery-truck-after {
		@include icon(delivery-truck, after);
	}

	.i-facebook-before {
		@include icon(facebook);
	}

	.i-facebook-after {
		@include icon(facebook, after);
	}

	.i-hamburger-before {
		@include icon(hamburger);
	}

	.i-hamburger-after {
		@include icon(hamburger, after);
	}

	.i-heart-fill-before {
		@include icon(heart-fill);
	}

	.i-heart-fill-after {
		@include icon(heart-fill, after);
	}

	.i-heart-before {
		@include icon(heart);
	}

	.i-heart-after {
		@include icon(heart, after);
	}

	.i-info-before {
		@include icon(info);
	}

	.i-info-after {
		@include icon(info, after);
	}

	.i-instagram-before {
		@include icon(instagram);
	}

	.i-instagram-after {
		@include icon(instagram, after);
	}

	.i-letter-2-before {
		@include icon(letter-2);
	}

	.i-letter-2-after {
		@include icon(letter-2, after);
	}

	.i-letter-before {
		@include icon(letter);
	}

	.i-letter-after {
		@include icon(letter, after);
	}

	.i-link-before {
		@include icon(link);
	}

	.i-link-after {
		@include icon(link, after);
	}

	.i-linkedin-before {
		@include icon(linkedin);
	}

	.i-linkedin-after {
		@include icon(linkedin, after);
	}

	.i-list-before {
		@include icon(list);
	}

	.i-list-after {
		@include icon(list, after);
	}

	.i-lock-before {
		@include icon(lock);
	}

	.i-lock-after {
		@include icon(lock, after);
	}

	.i-logout-before {
		@include icon(logout);
	}

	.i-logout-after {
		@include icon(logout, after);
	}

	.i-minus-before {
		@include icon(minus);
	}

	.i-minus-after {
		@include icon(minus, after);
	}

	.i-paypal-before {
		@include icon(paypal);
	}

	.i-paypal-after {
		@include icon(paypal, after);
	}

	.i-pinterest-before {
		@include icon(pinterest);
	}

	.i-pinterest-after {
		@include icon(pinterest, after);
	}

	.i-plus-before {
		@include icon(plus);
	}

	.i-plus-after {
		@include icon(plus, after);
	}

	.i-search-before {
		@include icon(search);
	}

	.i-search-after {
		@include icon(search, after);
	}

	.i-star-before {
		@include icon(star);
	}

	.i-star-after {
		@include icon(star, after);
	}

	.i-tick-before {
		@include icon(tick);
	}

	.i-tick-after {
		@include icon(tick, after);
	}

	.i-twitter-before {
		@include icon(twitter);
	}

	.i-twitter-after {
		@include icon(twitter, after);
	}

	.i-user-data-before {
		@include icon(user-data);
	}

	.i-user-data-after {
		@include icon(user-data, after);
	}

	.i-user-before {
		@include icon(user);
	}

	.i-user-after {
		@include icon(user, after);
	}

	.i-youtube-before {
		@include icon(youtube);
	}

	.i-youtube-after {
		@include icon(youtube, after);
	}

}
/* stylelint-enable */
